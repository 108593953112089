export const environment = {
	production: false,
	// api donde se desplega qa: cobranzadigital-qa.apps.ambientesbc.com
	apiEndPointCobranza: "https://alternativafinanciera-qa.apps.ambientesbc.com",
	// URL_BASE a la cual pegan los servicios - dependiendo del ambiente
  apiEndPointFirma : "https://electronic-signature-qa.apps.ambientesbc.com",
	urlServices:
		"https://filialesexterior-ext-qa.apps.ambientesbc.com/cobranza-digital/ms-edge",
	environment: "certification"
};
